import {
  Subtitle2,
  makeStyles,
  mergeClasses,
  shorthands,
  tokens,
} from '@fluentui/react-components'
import type { ReactNode } from 'react'

const useStyles = makeStyles({
  container: {
    width: '100%',
    boxSizing: 'border-box',
    boxShadow: tokens.shadow4,
    backgroundColor: tokens.colorNeutralBackground3,
    ...shorthands.padding(tokens.spacingVerticalL),
    ...shorthands.borderRadius(tokens.borderRadiusXLarge),
    ...shorthands.border(`1px solid ${tokens.colorNeutralStroke2}`),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    columnGap: tokens.spacingHorizontalL,
    marginBottom: tokens.spacingVerticalL,
  },
})

interface Props {
  children: ReactNode
  title?: ReactNode
  icon?: ReactNode
  actions?: ReactNode
  className?: string
}

export function Panel(props: Props): JSX.Element {
  const { title, icon, actions, children, className } = props

  const styles = useStyles()

  return (
    <div className={mergeClasses(styles.container, className)}>
      {title || icon || actions
        ? (
          <div className={styles.header}>
            {icon}
            <Subtitle2>{title}</Subtitle2>
            {actions}
          </div>
          )
        : null}
      {children}
    </div>
  )
}
