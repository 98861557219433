import {
  Body1,
  Button,
  Input,
  Label,
  OverlaySpinner,
  Text,
} from '@aisekisan/bond'
import { Mail20Regular } from '@fluentui/react-icons'
import { useForm } from 'react-hook-form'
import { parseServerError } from '@aisekisan/anya-api'
import { type AccountResetPasswordByEmailBody, useAccountResetPasswordByEmail } from '@aisekisan/anya-api'
import { useStyles } from '../styles'
import { T } from '@/libs/intl/t'
import { useT } from '@/libs/intl/useT'
import { ErrorAlert } from '@/libs/ui/ErrorAlert'

type Values = AccountResetPasswordByEmailBody

export function RequestResetPasswordForm(props: {
  setSent: (value: false | string) => void
}): JSX.Element {
  const { setSent } = props

  const t = useT()
  const styles = useStyles()

  const form = useForm<Values>({
    defaultValues: {
      email: '',
    },
  })

  const resetPasswordByEmail = useAccountResetPasswordByEmail()

  const onSubmit = (body: Values) => {
    resetPasswordByEmail.mutate(
      { email: body.email },
      { onSuccess: () => void setSent(body.email) },
    )
  }

  return (
    <div className={styles.flexCol}>
      <Text className={styles.title3}>
        <T id="reset-password.title" />
      </Text>
      {resetPasswordByEmail.isError
        ? (
          <ErrorAlert
            title={t('reset-password.error.title')}
            help={t('reset-password.error.help')}
            detail={parseServerError(resetPasswordByEmail.error)}
            onClose={() => resetPasswordByEmail.reset()}
          />
          )
        : null}
      <Body1 className={styles.textBody}>
        <T id="reset-password.accept.form.help" />
      </Body1>
      <OverlaySpinner
        visible={resetPasswordByEmail.isPending}
        appearance="primary"
      />
      <form onSubmit={form.handleSubmit(onSubmit)} className={styles.flexCol}>
        <div className={styles.field}>
          <Label htmlFor="email">{t('account.field.email')}</Label>
          <Input
            type="email"
            id="email"
            {...form.register('email')}
            placeholder="name@gmail.com"
            contentBefore={<Mail20Regular />}
            size="large"
            appearance="filled-darker"
            required
          />
        </div>

        <div className={styles.fullWidth}>
          <Button type="submit" appearance="primary" size="large">
            <T id="reset-password.submit" />
          </Button>
        </div>
      </form>
    </div>
  )
}
