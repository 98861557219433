import { useCategoryList } from '@aisekisan/anya-api'
import { Button, Panel, makeStyles, tokens } from '@aisekisan/bond'
import { AddRegular, BuildingHome24Regular } from '@fluentui/react-icons'
import type { ReactElement } from 'react'
import { useState } from 'react'
import { EmptyContent } from '../empty-content'
import { CardInfo } from './card-info'
import { CategoryCreate } from './create'
import { T } from '@/libs/intl/t'

const useStyles = makeStyles({
  list: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalL,
  },
})

interface Props {
  constructionID: string
}

export function CategoryList(props: Props): ReactElement | null {
  const { constructionID } = props

  const styles = useStyles()

  const categories = useCategoryList({ constructionID }).data
  const [create, setCreate] = useState<boolean>(false)

  if (typeof categories === 'undefined')
    return null

  return (
    <Panel
      title={<T id="category.list.title" />}
      icon={<BuildingHome24Regular />}
      actions={(
        <Button icon={<AddRegular />} onClick={() => setCreate(true)}>
          <T id="category.list.create" />
        </Button>
      )}
    >
      {create && (
        <CategoryCreate
          categoryList={categories}
          constructionID={constructionID}
          close={() => setCreate(false)}
        />
      )}
      {categories.length > 0
        ? (
          <div className={styles.list}>
            {categories.map(category => (
              <CardInfo {...category} key={category.value.id} />
            ))}
          </div>
          )
        : <EmptyContent />}
    </Panel>
  )
}
