import { Button, Text, ToolbarDivider } from '@aisekisan/bond'
import { Info20Regular } from '@fluentui/react-icons'
import type { ReactNode } from 'react'
import { useStyles } from './styles'
import { ToggleIntlSelect } from '@/provider/intl/toggle'
import { T } from '@/libs/intl/t'

function Header(): JSX.Element {
  const styles = useStyles()

  return (
    <div className={styles.header}>
      <div>
        <a href="/sign-in" className={styles.link}>
          <div className={styles.logo}>
            <img src="/favicon.png" alt="SekisanLogo" />
            <Text weight="semibold">
              <T id="app.name.sekisan" />
            </Text>
          </div>
        </a>
      </div>
      <div className={styles.rightSection}>
        <ToggleIntlSelect />
        <ToolbarDivider className={styles.toolbarDivider} />
        <Button
          appearance="transparent"
          icon={<Info20Regular />}
          className={styles.infoButton}
          as="a"
          href="https://docs.aisekisan.com"
          target="_blank"
        />
      </div>
    </div>
  )
}

export function AccountLayout(props: {
  children: ReactNode
  navigate?: ReactNode
}): JSX.Element {
  const { children, navigate } = props
  const styles = useStyles()
  return (
    <div className={styles.layout}>
      <div className={styles.main}>
        <div className={styles.container}>
          <Header />
          {children}
          <div className={styles.navigateFooter}>{navigate}</div>
        </div>
      </div>
      <div className={styles.illustration} />
    </div>
  )
}
