import { Route, Switch } from 'wouter'
import { AppPage } from './AppPage'
import { ConstructionDetailPage } from './pages/construction/detail'
import { OrgListPage } from './pages/org'
import { OrgDetailPage } from './pages/org/detail'
import { OrgPresetDetailPage } from './pages/org/preset'
import { ConstructionPropertyDetailPage } from './pages/construction-propperty/detail'
import { PropertyDetailPage } from './pages/org-property/detail'
import { PageNotFound } from './pages/public/not-found'
import { PUBLIC_ROUTES } from './routes'
import { OrgPropertyCreatePage } from './pages/org-property/create'
import { CategoryDetailPage } from './pages/category/detail'
import { ConstructionPropertyCreate } from './pages/construction-propperty/create'
import { ManageAccountPage } from './pages/settings/manage-account'

export function AppRouter() {
  return (
    <Switch>
      {/* PUBLIC ROUTES */}
      {PUBLIC_ROUTES.map(route => (
        <Route key={route.path} path={route.path} component={route.component} />
      ))}

      {/* APP ROUTES */}
      <Route path="/home">
        <OrgListPage />
      </Route>

      <Route path="/org/:id">{({ id }) => <OrgDetailPage id={id} />}</Route>

      <Route path="/org-preset/:id">
        {({ id }) => <OrgPresetDetailPage id={id} />}
      </Route>

      <Route path="/org-preset-property/:id">
        {({ id }) => <PropertyDetailPage treeID={id} />}
      </Route>

      <Route path="/org-preset-property/:orgPresetId/create">
        {({ orgPresetId }) => <OrgPropertyCreatePage orgPresetID={orgPresetId} />}
      </Route>

      <Route path="/construction/:id">
        {({ id }) => <ConstructionDetailPage id={id} />}
      </Route>

      <Route path="/construction-property/:id">
        {({ id }) => <ConstructionPropertyDetailPage treeID={id} />}
      </Route>

      <Route path="/construction-property/:constructionId/create">
        {({ constructionId }) => <ConstructionPropertyCreate constructionID={constructionId} />}
      </Route>

      <Route path="/category/:id">
        {({ id }) => <CategoryDetailPage id={id} />}
      </Route>

      <Route path="/settings">
        <ManageAccountPage />
      </Route>

      {/* Default route */}
      <Route>
        <AppPage>
          <PageNotFound />
        </AppPage>
      </Route>
    </Switch>
  )
}
