import type {
  CheckboxProps,
} from '@aisekisan/bond'
import {
  Button,
  Card,
  CardFooter,
  CardPreview,
  Checkbox,
  Link,
  Menu,
  MenuDivider,
  MenuGroup,
  MenuGroupHeader,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Subtitle2,
  makeStyles,
  shorthands,
  tokens,
} from '@aisekisan/bond'
import {
  ArrowSwapRegular,
  CopyRegular,
  DeleteRegular,
  MoreHorizontal20Regular,
  PaddingDownRegular,
  PaddingTopRegular,
  Pen20Regular,
  PrintRegular,
  RenameRegular,
} from '@fluentui/react-icons'
import type { Dispatch, ReactElement, SetStateAction } from 'react'
import { useCallback, useState } from 'react'
import type { PageDetail } from '@aisekisan/anya-api'
import { RenamePage } from './rename'
import { DeletePage } from './delete'
import { DuplicatePage } from './duplicate'
import { ReplacePage } from './replace-page'
import { AddPages } from './add-pages'
import { PrintPage } from './export/print'
import { T } from '@/libs/intl/t'
import { DefaultImage } from '@/icons/DefaultImage'

const useStyles = makeStyles({
  card: {
    backgroundColor: tokens.colorNeutralBackground1,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: tokens.shadow4,
    ...shorthands.padding(tokens.spacingHorizontalL),
    ...shorthands.borderRadius(tokens.borderRadiusXLarge),
    rowGap: tokens.spacingHorizontalL,
    width: '320px',
    justifyContent: 'space-between',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: tokens.spacingHorizontalL,
  },
  preview: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: '100%',
  },
  default_thumbnail: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '204px',
  },
  thumbnail: {
    display: 'flex',
    alignItems: 'flex-end',
  },
})

const EDITOR_DOMAIN = import.meta.env.VITE_EDITOR_DOMAIN

interface PageCardProps {
  page: PageDetail
  categoryID: string
  selected: string[]
  setSelected: Dispatch<SetStateAction<string[]>>
  selectable: boolean
}

export function PageCard(props: PageCardProps): ReactElement {
  const { page, categoryID, setSelected, selected, selectable } = props
  const { name, id, thumbnailSignedURL } = page

  const styles = useStyles()

  const [rename, setRename] = useState<PageDetail | null>(null)
  const [replace, setReplace] = useState<PageDetail | null>(null)
  const [duplicate, setDuplicate] = useState<PageDetail | null>(null)
  const [addPageBefore, setAddPageBefore] = useState<PageDetail | null>(null)
  const [addPageAfter, setAddPageAfter] = useState<PageDetail | null>(null)
  const [printPage, setPrintPage] = useState<PageDetail | null>(null)
  const [deletePage, setDeletePage] = useState<PageDetail | null>(null)
  const [checked, setChecked] = useState<CheckboxProps['checked']>(selected.includes(id))

  const onChangeCheckbox = (_: any, data: any) => setChecked(data.checked)

  const onSelectedChange = useCallback(
    (e: any, data: any) => {
      onChangeCheckbox(e, data)
      if (data.checked)
        setSelected([...selected, id])
      else
        setSelected(selected.filter(item => item !== id))
    },
    [id, selected, setSelected],
  )

  return (
    <>
      {rename && (
        <RenamePage
          page={page}
          close={() => setRename(null)}
        />
      )}

      {duplicate && (
        <DuplicatePage
          page={page}
          close={() => setDuplicate(null)}
        />
      )}

      {replace && (
        <ReplacePage
          pageID={page.id}
          close={() => setReplace(null)}
        />
      )}

      {deletePage && (
        <DeletePage
          page={page}
          close={() => setDeletePage(null)}
        />
      )}

      {addPageBefore && (
        <AddPages
          beforePage={page.id.toString()}
          close={() => setAddPageBefore(null)}
          categoryID={categoryID}
        />
      )}

      {addPageAfter && (
        <AddPages
          afterPage={page.id.toString()}
          close={() => setAddPageAfter(null)}
          categoryID={categoryID}
        />
      )}

      {printPage && (
        <PrintPage
          close={() => setPrintPage(null)}
          id={page.id}
        />
      )}

      <Card
        className={styles.card}
        floatingAction={selectable
          ? (
            <Checkbox
              onChange={(e, data) => onSelectedChange(e, data)}
              checked={checked}
              defaultChecked={checked}
            />
            )
          : null}
        selected={selected.includes(id)}
        onSelectionChange={(e, data) => onSelectedChange(e, data)}
      >
        <div className={styles.preview}>
          <Subtitle2>{name}</Subtitle2>
          <CardPreview>
            <Link
              href={`//${EDITOR_DOMAIN}/?page=${id}`}
              target="_blank"
              className={styles.thumbnail}
            >
              { thumbnailSignedURL
                ? (
                  <img
                    src={thumbnailSignedURL}
                    width="288px"
                    height="204px"
                    alt={`thumbnail-${id}`}
                    style={{ objectFit: 'contain' }}
                  />
                  )
                : (
                  <div className={styles.default_thumbnail}>
                    <DefaultImage width={240} height={120} />
                  </div>
                  )}

            </Link>
          </CardPreview>
        </div>
        <CardFooter
          action={(
            <div className={styles.row}>
              <Button
                appearance="primary"
                icon={<Pen20Regular />}
                onClick={() => {
                  window.open(`//${EDITOR_DOMAIN}/?page=${id}`, '_blank')
                }}
              >
                <T id="page.overview.annotate" />
              </Button>

              <Menu>
                <MenuTrigger disableButtonEnhancement>
                  <Button icon={<MoreHorizontal20Regular />}>
                    <T id="page.action.more" />
                  </Button>
                </MenuTrigger>

                <MenuPopover>
                  <MenuList>
                    <MenuGroup>
                      <MenuItem
                        icon={<RenameRegular />}
                        onClick={() => setRename(page)}
                      >
                        <T id="page.overview.more.rename" />
                      </MenuItem>
                      <MenuItem
                        icon={<PrintRegular />}
                        onClick={() => setPrintPage(page)}
                      >
                        <T id="page.print.page.title" />
                      </MenuItem>
                      <MenuItem
                        icon={<CopyRegular />}
                        onClick={() => setDuplicate(page)}
                      >
                        <T id="page.overview.more.duplicate" />
                      </MenuItem>
                      <MenuItem
                        icon={<ArrowSwapRegular />}
                        onClick={() => setReplace(page)}
                      >
                        <T id="page.overview.more.replace" />
                      </MenuItem>
                    </MenuGroup>
                    <MenuDivider />
                    <MenuGroup>
                      <MenuItem
                        icon={<DeleteRegular />}
                        onClick={() => setDeletePage(page)}
                      >
                        <T id="page.overview.more.delete" />
                      </MenuItem>
                    </MenuGroup>
                    <MenuDivider />
                    <MenuGroupHeader>
                      <T id="page.action.add.pages" />
                    </MenuGroupHeader>
                    <MenuGroup>
                      <MenuItem
                        icon={<PaddingTopRegular />}
                        onClick={() => setAddPageBefore(page)}
                      >
                        <T id="page.add.before.page.title" />
                      </MenuItem>
                      <MenuItem
                        icon={<PaddingDownRegular />}
                        onClick={() => setAddPageAfter(page)}
                      >
                        <T id="page.add.after.page.title" />
                      </MenuItem>
                    </MenuGroup>
                  </MenuList>
                </MenuPopover>
              </Menu>
            </div>
          )}
        />
      </Card>
    </>
  )
}
