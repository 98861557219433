import {
  makeStyles,
  shorthands,
  tokens,
  typographyStyles,
} from '@aisekisan/bond'

export const useStyles = makeStyles({
  layout: {
    display: 'grid',
    width: '100%',
    gridRowGap: tokens.spacingHorizontalXXL,
    gridTemplateColumns: 'repeat(auto-fit, minmax(360px, 1fr))',
    backgroundColor: tokens.colorNeutralBackground4,
    height: '100vh',
    paddingTop: tokens.spacingHorizontalMNudge,
    paddingBottom: tokens.spacingHorizontalMNudge,
  },
  header: {
    display: 'grid',
    gridRowGap: tokens.spacingHorizontalXXL,
    gridTemplateColumns: '1fr 1.5fr',
    backgroundColor: tokens.colorNeutralBackground4,
    ...shorthands.margin(tokens.spacingHorizontalS, 0),
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  navigateFooter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    ...shorthands.margin(tokens.spacingVerticalL, 0),
  },
  navigateFlex: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalM,
  },
  title3: typographyStyles.title3,
  center: {
    alignSelf: 'center',
  },
  container: {
    width: '360px',
  },
  card: {
    ...shorthands.borderRadius(tokens.borderRadiusXLarge),
    backgroundColor: tokens.colorNeutralForegroundOnBrand,
    ...shorthands.padding(tokens.spacingHorizontalXXL),
    flexGrow: 1,
    position: 'relative',
    boxShadow: tokens.shadow4,
  },
  illustration: {
    ...shorthands.borderRadius(tokens.borderRadiusXLarge),
    backgroundColor: tokens.colorNeutralForegroundOnBrand,
    textAlign: 'center',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: tokens.spacingVerticalXL,
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    rowGap: tokens.spacingVerticalL,
  },
  textBody: {
    color: tokens.colorNeutralForeground3,
  },
  hintText: {
    ...typographyStyles.caption1,
    color: tokens.colorNeutralForeground3,
  },
  logo: {
    display: 'flex',
    flexDirection: 'row',
    width: '90px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  leftSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 1,
    rowGap: '24px',
  },
  breadcrumb: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row',
    marginLeft: tokens.spacingHorizontalL,
  },
  field: {
    display: 'grid',
    rowGap: tokens.spacingVerticalXS,
  },
  buttonAsLink: {
    ...shorthands.padding(0),
  },
  fullWidth: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  alignCenter: {
    alignSelf: 'center',
  },
  rightSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  toolbarDivider: {
    ...shorthands.padding(0, tokens.spacingHorizontalS),
    alignSelf: 'center',
  },
  infoButton: {
    ...shorthands.padding('auto', 0),
    minWidth: '24px',
    height: '24px',
    alignSelf: 'center',
  },
  link: {
    ...shorthands.textDecoration('none'),
    color: tokens.colorNeutralForeground1,
  },
})
