import {
  OverlaySpinner,
} from '@aisekisan/bond'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { parseServerError, useConstructionCreate, useGlobalPresetBind, useOrgPresetBind } from '@aisekisan/anya-api'
import type { ReactElement } from 'react'
import { ConstructionFormDialog } from './form/form'
import type { ConstructionFormValues } from './form/type'
import { ErrorAlert } from '@/libs/ui/ErrorAlert'
import { useT } from '@/libs/intl/useT'

interface Props {
  orgID: string
  close: () => void
}

export function ConstructionCreate(props: Props): ReactElement {
  const { orgID, close } = props

  const t = useT()

  const bindOrgPreset = useOrgPresetBind()
  const bindGlobalPreset = useGlobalPresetBind()

  const schema = z.object({
    name: z.string().min(1, { message: t('form.error.required_field') }),
    code: z.string().min(1, { message: t('form.error.required_field') }),
    presetID: z.string().min(1, { message: t('form.error.required_field') }),
    presetType: z.string().nullable(),
  })

  const form = useForm<ConstructionFormValues>({
    defaultValues: {
      name: '',
      code: '',
      presetID: '',
      presetType: null,
    },
    resolver: zodResolver(schema),
  })

  const create = useConstructionCreate({ orgID })

  const onSubmit = (values: ConstructionFormValues) => {
    const constructionCreatePayload = {
      code: values.code,
      name: values.name,
    }

    create.mutate(constructionCreatePayload, {
      onSuccess: async (data) => {
        if (values.presetType === 'orgPreset' && values.presetID) {
          await bindOrgPreset.mutate(
            {
              constructionID: data.id,
              orgPresetId: values.presetID,
            },
          )
        }
        else if (values.presetType === 'globalPreset' && values.presetID) {
          await bindGlobalPreset.mutate(
            {
              constructionID: data.id,
              globalPresetId: values.presetID,
            },
          )
        }
        close()
      },
    })
  }

  return (
    <ConstructionFormDialog form={form} close={close} submit={onSubmit} isCreate orgID={orgID}>
      <OverlaySpinner visible={create.isPending} appearance="primary" />
      {create.isError
        ? (
          <ErrorAlert
            title={t('construction.update.error')}
            help={t('all.error.review')}
            detail={parseServerError(create.error)}
            onClose={() => create.reset()}
          />
          )
        : null}
    </ConstructionFormDialog>
  )
}
