/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccountInfo } from '../models/AccountInfo';
import type { AIModel } from '../models/AIModel';
import type { AIModelOutputFireProtectionPipes } from '../models/AIModelOutputFireProtectionPipes';
import type { AIModelOutputSprinkleHeads } from '../models/AIModelOutputSprinkleHeads';
import type { AIModelOutputWindoor } from '../models/AIModelOutputWindoor';
import type { AnnotationCreate } from '../models/AnnotationCreate';
import type { AnnotationDetail } from '../models/AnnotationDetail';
import type { AnnotationUpdate } from '../models/AnnotationUpdate';
import type { Box } from '../models/Box';
import type { CategoryDetail } from '../models/CategoryDetail';
import type { ConstructionDetail } from '../models/ConstructionDetail';
import type { ConstructionMemberDetail } from '../models/ConstructionMemberDetail';
import type { ConstructionMetadataDetail } from '../models/ConstructionMetadataDetail';
import type { ConstructionModuleDetail } from '../models/ConstructionModuleDetail';
import type { ConstructionRole } from '../models/ConstructionRole';
import type { Currency } from '../models/Currency';
import type { EquipmentAttributeTree } from '../models/EquipmentAttributeTree';
import type { EquipmentAttributeTreeInOrgPreset } from '../models/EquipmentAttributeTreeInOrgPreset';
import type { EquipmentAttributeTreeNode } from '../models/EquipmentAttributeTreeNode';
import type { EquipmentAttributeTreeType } from '../models/EquipmentAttributeTreeType';
import type { EquipmentClass } from '../models/EquipmentClass';
import type { Feedback } from '../models/Feedback';
import type { FireProtectionSprinkleHead } from '../models/FireProtectionSprinkleHead';
import type { GlobalPresetDetail } from '../models/GlobalPresetDetail';
import type { Invoice } from '../models/Invoice';
import type { InvoiceDetail } from '../models/InvoiceDetail';
import type { Locale } from '../models/Locale';
import type { LocaledEquipmentClass } from '../models/LocaledEquipmentClass';
import type { Market } from '../models/Market';
import type { OrgDetail } from '../models/OrgDetail';
import type { OrgInvitationDetail } from '../models/OrgInvitationDetail';
import type { OrgInvitationStatus } from '../models/OrgInvitationStatus';
import type { OrgMemberDetail } from '../models/OrgMemberDetail';
import type { OrgPresetDetail } from '../models/OrgPresetDetail';
import type { OrgReportingTemplateDetail } from '../models/OrgReportingTemplateDetail';
import type { OrgRole } from '../models/OrgRole';
import type { OrgStats } from '../models/OrgStats';
import type { PageDetail } from '../models/PageDetail';
import type { PredictEquipmentsByAIOutput } from '../models/PredictEquipmentsByAIOutput';
import type { RotationAngle } from '../models/RotationAngle';
import type { StringKeyValue } from '../models/StringKeyValue';
import type { WindoorCreate } from '../models/WindoorCreate';
import type { WindoorDetail } from '../models/WindoorDetail';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class DefaultService {
    /**
     * Change password
     *
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static changePassword(
        requestBody: {
            newPassword: string;
            oldPassword: string;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/account/change-password',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized - Invalid Authentication Credentials`,
                403: `Forbidden`,
                404: `Account not found`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Get Account info
     *
     * @returns AccountInfo OK
     * @throws ApiError
     */
    public static getAccountInfo(): CancelablePromise<AccountInfo> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/account/info',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized - Invalid Authentication Credentials`,
                403: `Forbidden`,
                404: `Account not found`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * 2FA with TOTP
     *
     * @returns any OK
     * @throws ApiError
     */
    public static disableTotp(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/account/totp/disable',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized - Invalid Authentication Credentials`,
                403: `Forbidden`,
                404: `Account not found`,
                423: `Account is locked`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * 2FA with TOTP
     *
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static enableTotp(
        requestBody: {
            passcode: string;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/account/totp/enable',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized - Invalid Authentication Credentials`,
                403: `Forbidden`,
                404: `Account not found`,
                423: `Account is locked`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * 2FA with TOTP
     *
     * @returns any OK
     * @throws ApiError
     */
    public static registerTotp(): CancelablePromise<{
        qrcode: string;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/account/totp/register',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized - Invalid Authentication Credentials`,
                403: `Forbidden`,
                404: `Account not found`,
                409: `Already enabled TOTP`,
                423: `Account is locked`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * 2FA with TOTP
     *
     * @returns any OK
     * @throws ApiError
     */
    public static getTotpStatus(): CancelablePromise<{
        enabled: boolean;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/account/totp/status',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized - Invalid Authentication Credentials`,
                403: `Forbidden`,
                404: `Account not found`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Update Account Info
     *
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static updateAccountInfo(
        requestBody: {
            companyName: string;
            firstName: string;
            firstNameKana: string;
            lastName: string;
            lastNameKana: string;
            locale: Locale;
            middleName: string;
            middleNameKana: string;
            phone: string;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/account/update',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized - Invalid Authentication Credentials`,
                403: `Forbidden`,
                404: `Account not found`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Predict/Detect equipments by AI
     *
     * @param formData
     * @returns PredictEquipmentsByAIOutput OK
     * @throws ApiError
     */
    public static predictEquipmentsByAi(
        formData: {
            feedback?: Feedback;
            image: Blob;
            model: AIModel;
            pageID: string;
            sessionID: string;
            /**
             * Due to issue https://github.com/ferdikoomen/openapi-typescript-codegen/issues/1049,
             * we must define this field as string instead of object. In this way, Frontend side
             * must JSON.stringify before sending the request.
             *
             * Cropping Model looks like:
             * struct {
                 * Scale float64 `json:"scale"`
                 * X     float64 `json:"x"`
                 * Y     float64 `json:"y"`
                 * W     float64 `json:"w"`
                 * H     float64 `json:"h"`
                 * }
                 *
                 */
                cropping?: string;
            },
        ): CancelablePromise<PredictEquipmentsByAIOutput> {
            return __request(OpenAPI, {
                method: 'POST',
                url: '/ai/predict/single-model',
                formData: formData,
                mediaType: 'multipart/form-data',
                errors: {
                    400: `Bad Request`,
                    401: `Unauthorized - Invalid Authentication Credentials`,
                    403: `Forbidden`,
                    429: `The number of AI Prediction requests exceeds the limitation`,
                    500: `Internal Server Error`,
                },
            });
        }
        /**
         * Predict Fire Protection SprinkleHeads by AI
         *
         * @param formData
         * @returns AIModelOutputSprinkleHeads OK
         * @throws ApiError
         */
        public static predictFireProtectionSprinkleHeadsByAi(
            formData: {
                pageID: string;
                sessionID: string;
                image: Blob;
                groupID: string;
                templateImages: Array<Blob>;
            },
        ): CancelablePromise<AIModelOutputSprinkleHeads> {
            return __request(OpenAPI, {
                method: 'POST',
                url: '/ai/predict/fireprotection/sprinklehead',
                formData: formData,
                mediaType: 'multipart/form-data',
                errors: {
                    400: `Bad Request`,
                    401: `Unauthorized - Invalid Authentication Credentials`,
                    403: `Forbidden`,
                    429: `The number of AI Prediction requests exceeds the limitation`,
                    500: `Internal Server Error`,
                },
            });
        }
        /**
         * Fire Protection SprinkleHeads Normalize
         *
         * @param requestBody
         * @returns any OK
         * @throws ApiError
         */
        public static fireProtectionSprinkleHeadsNormalize(
            requestBody: {
                pageID: string;
                scale: number;
                max: number;
            },
        ): CancelablePromise<{
            payload: string;
        }> {
            return __request(OpenAPI, {
                method: 'POST',
                url: '/ai/predict/fireprotection/sprinklehead_normalize',
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    400: `Bad Request`,
                    401: `Unauthorized - Invalid Authentication Credentials`,
                    403: `Forbidden`,
                    500: `Internal Server Error`,
                },
            });
        }
        /**
         * Predict Fire Protection Pipes by AI
         *
         * @param formData
         * @returns AIModelOutputFireProtectionPipes OK
         * @throws ApiError
         */
        public static predictFireProtectionPipesByAi(
            formData: {
                pageID: string;
                sessionID: string;
                image: Blob;
                dummySprinkleHeads?: FireProtectionSprinkleHead;
                /**
                 * Please do not input anything to dummySprinkleHeads. It's just a dummy field to trigger OpenAPI Codegen
                 * for generating FireProtectionSprinkleHead model.
                 *
                 * Due to issue https://github.com/ferdikoomen/openapi-typescript-codegen/issues/1049,
                 * we must define this field as string instead of object. In this way, Frontend side
                 * must JSON.stringify before sending the request.
                 *
                 * Model looks like: []FireProtectionSprinkleHead
                 *
                 */
                sprinkleHeads: string;
                dummyWaterSources?: Box;
                /**
                 * Please do not input anything to dummyWaterSources. It's just a dummy field to trigger OpenAPI Codegen
                 * for generating Box model.
                 *
                 * Due to issue https://github.com/ferdikoomen/openapi-typescript-codegen/issues/1049,
                 * we must define this field as string instead of object. In this way, Frontend side
                 * must JSON.stringify before sending the request.
                 *
                 * Model looks like: []Box
                 *
                 */
                waterSources: string;
                /**
                 * Due to issue https://github.com/ferdikoomen/openapi-typescript-codegen/issues/1049,
                 * we must define this field as string instead of object. In this way, Frontend side
                 * must JSON.stringify before sending the request.
                 *
                 * Cropping Model looks like:
                 * struct {
                     * Scale float64 `json:"scale"`
                     * X     float64 `json:"x"`
                     * Y     float64 `json:"y"`
                     * W     float64 `json:"w"`
                     * H     float64 `json:"h"`
                     * }
                     *
                     */
                    cropping: string;
                },
            ): CancelablePromise<AIModelOutputFireProtectionPipes> {
                return __request(OpenAPI, {
                    method: 'POST',
                    url: '/ai/predict/fireprotection/pipe',
                    formData: formData,
                    mediaType: 'multipart/form-data',
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        422: `Unprocessable Content`,
                        429: `The number of AI Prediction requests exceeds the limitation`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Predict Fire Protection Pipe Diameters by AI
             *
             * @param formData
             * @returns AIModelOutputFireProtectionPipes OK
             * @throws ApiError
             */
            public static predictFireProtectionPipeDiametersByAi(
                formData: {
                    pageID: string;
                    sessionID: string;
                    image: Blob;
                    /**
                     * Due to issue https://github.com/ferdikoomen/openapi-typescript-codegen/issues/1049,
                     * we must define this field as string instead of object. In this way, Frontend side
                     * must JSON.stringify before sending the request.
                     *
                     * Model looks like: []FireProtectionSprinkleHead
                     *
                     */
                    sprinkleHeads: string;
                    /**
                     * Please do not input anything to dummyWaterSources. It's just a dummy field to trigger OpenAPI Codegen
                     * for generating Box model.
                     *
                     * Due to issue https://github.com/ferdikoomen/openapi-typescript-codegen/issues/1049,
                     * we must define this field as string instead of object. In this way, Frontend side
                     * must JSON.stringify before sending the request.
                     *
                     * Model looks like: []Box
                     *
                     */
                    waterSources: string;
                    /**
                     * Due to issue https://github.com/ferdikoomen/openapi-typescript-codegen/issues/1049,
                     * we must define this field as string instead of object. In this way, Frontend side
                     * must JSON.stringify before sending the request.
                     *
                     * Model looks like: []AIOutputFireProtectionSegment
                     *
                     */
                    segments: string;
                },
            ): CancelablePromise<AIModelOutputFireProtectionPipes> {
                return __request(OpenAPI, {
                    method: 'POST',
                    url: '/ai/predict/fireprotection/pipediameter',
                    formData: formData,
                    mediaType: 'multipart/form-data',
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        429: `The number of AI Prediction requests exceeds the limitation`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Delete all annotations in Page
             *
             * @param requestBody
             * @returns any OK
             * @throws ApiError
             */
            public static deleteAllAnnotations(
                requestBody: {
                    pageID: string;
                },
            ): CancelablePromise<any> {
                return __request(OpenAPI, {
                    method: 'DELETE',
                    url: '/annotation/delete/all',
                    body: requestBody,
                    mediaType: 'application/json',
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        423: `category/page is locked`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Delete annotations
             *
             * @param requestBody
             * @returns any OK
             * @throws ApiError
             */
            public static deleteAnnotations(
                requestBody: {
                    annotationIDs: Array<string>;
                    pageID: string;
                },
            ): CancelablePromise<any> {
                return __request(OpenAPI, {
                    method: 'DELETE',
                    url: '/annotation/delete/many',
                    body: requestBody,
                    mediaType: 'application/json',
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        423: `category/page is locked`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Delete annotation
             *
             * @param requestBody
             * @returns any OK
             * @throws ApiError
             */
            public static deleteAnnotation(
                requestBody: {
                    annotationID: string;
                    pageID: string;
                },
            ): CancelablePromise<any> {
                return __request(OpenAPI, {
                    method: 'DELETE',
                    url: '/annotation/delete/one',
                    body: requestBody,
                    mediaType: 'application/json',
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        423: `category/page is locked`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * List annotations
             *
             * @param pageId
             * @returns AnnotationDetail OK
             * @throws ApiError
             */
            public static listAnnotations(
                pageId: string,
            ): CancelablePromise<Array<AnnotationDetail>> {
                return __request(OpenAPI, {
                    method: 'GET',
                    url: '/annotation/list',
                    query: {
                        'pageID': pageId,
                    },
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Report annotations. Returned rows are always sorted by Equipment and Material.
             *
             * @param constructionId
             * @param categoryId
             * @param pageId
             * @param equipmentClass
             * @param equipmentClassOther
             * @returns AnnotationDetail OK
             * @throws ApiError
             */
            public static reportAnnotations(
                constructionId: string,
                categoryId?: string,
                pageId?: string,
                equipmentClass?: EquipmentClass,
                equipmentClassOther?: string,
            ): CancelablePromise<Array<AnnotationDetail>> {
                return __request(OpenAPI, {
                    method: 'GET',
                    url: '/annotation/report',
                    query: {
                        'constructionID': constructionId,
                        'categoryID': categoryId,
                        'pageID': pageId,
                        'equipmentClass': equipmentClass,
                        'equipmentClassOther': equipmentClassOther,
                    },
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Search annotations
             *
             * @param constructionId
             * @param categoryId
             * @param pageId
             * @param filterModule
             * @param moduleId
             * @param equipmentClass
             * @param equipmentClassOther
             * @param equipmentType
             * @returns AnnotationDetail OK
             * @throws ApiError
             */
            public static searchAnnotations(
                constructionId: string,
                categoryId?: string,
                pageId?: string,
                filterModule?: boolean,
                moduleId?: string,
                equipmentClass?: EquipmentClass,
                equipmentClassOther?: string,
                equipmentType?: string,
            ): CancelablePromise<Array<AnnotationDetail>> {
                return __request(OpenAPI, {
                    method: 'GET',
                    url: '/annotation/search',
                    query: {
                        'constructionID': constructionId,
                        'categoryID': categoryId,
                        'pageID': pageId,
                        'filterModule': filterModule,
                        'moduleID': moduleId,
                        'equipmentClass': equipmentClass,
                        'equipmentClassOther': equipmentClassOther,
                        'equipmentType': equipmentType,
                    },
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Sync annotations
             *
             * @param requestBody
             * @returns string OK
             * @throws ApiError
             */
            public static syncAnnotations(
                requestBody: {
                    annotations: Array<AnnotationCreate>;
                    pageID: string;
                },
            ): CancelablePromise<Array<string>> {
                return __request(OpenAPI, {
                    method: 'PUT',
                    url: '/annotation/sync',
                    body: requestBody,
                    mediaType: 'application/json',
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        423: `category/page is locked`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Get annotation
             *
             * @param annotationId
             * @returns AnnotationDetail OK
             * @throws ApiError
             */
            public static getAnnotation(
                annotationId: string,
            ): CancelablePromise<AnnotationDetail> {
                return __request(OpenAPI, {
                    method: 'GET',
                    url: '/annotation/{annotationID}/detail',
                    path: {
                        'annotationID': annotationId,
                    },
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Update annotation
             *
             * @param annotationId
             * @param requestBody
             * @returns any OK
             * @throws ApiError
             */
            public static updateAnnotation(
                annotationId: string,
                requestBody: AnnotationUpdate,
            ): CancelablePromise<any> {
                return __request(OpenAPI, {
                    method: 'PATCH',
                    url: '/annotation/{annotationID}/update',
                    path: {
                        'annotationID': annotationId,
                    },
                    body: requestBody,
                    mediaType: 'application/json',
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        423: `category/page is locked`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Create new category
             *
             * @param requestBody
             * @returns any OK
             * @throws ApiError
             */
            public static createCategory(
                requestBody: {
                    constructionID: string;
                    name: string;
                    parentID?: string;
                },
            ): CancelablePromise<{
                id: string;
            }> {
                return __request(OpenAPI, {
                    method: 'POST',
                    url: '/category/create',
                    body: requestBody,
                    mediaType: 'application/json',
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        429: `The number of categories exceeds the limitation`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * List categories
             *
             * @param constructionId
             * @returns CategoryDetail OK
             * @throws ApiError
             */
            public static listCategories(
                constructionId: string,
            ): CancelablePromise<Array<CategoryDetail>> {
                return __request(OpenAPI, {
                    method: 'GET',
                    url: '/category/list',
                    query: {
                        'constructionID': constructionId,
                    },
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        404: `Not Found`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Get Category detail
             *
             * @param categoryId
             * @returns CategoryDetail OK
             * @throws ApiError
             */
            public static getCategoryDetail(
                categoryId: string,
            ): CancelablePromise<CategoryDetail> {
                return __request(OpenAPI, {
                    method: 'GET',
                    url: '/category/{categoryID}/detail',
                    path: {
                        'categoryID': categoryId,
                    },
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        404: `Not Found`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Move category
             *
             * @param categoryId
             * @param requestBody
             * @returns any OK
             * @throws ApiError
             */
            public static moveCategory(
                categoryId: string,
                requestBody: {
                    parentID?: string;
                },
            ): CancelablePromise<any> {
                return __request(OpenAPI, {
                    method: 'PATCH',
                    url: '/category/{categoryID}/move',
                    path: {
                        'categoryID': categoryId,
                    },
                    body: requestBody,
                    mediaType: 'application/json',
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Rename category
             *
             * @param categoryId
             * @param requestBody
             * @returns any OK
             * @throws ApiError
             */
            public static renameCategory(
                categoryId: string,
                requestBody: {
                    name: string;
                },
            ): CancelablePromise<any> {
                return __request(OpenAPI, {
                    method: 'PATCH',
                    url: '/category/{categoryID}/rename',
                    path: {
                        'categoryID': categoryId,
                    },
                    body: requestBody,
                    mediaType: 'application/json',
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Delete category
             *
             * @param categoryId
             * @returns any OK
             * @throws ApiError
             */
            public static deleteCategory(
                categoryId: string,
            ): CancelablePromise<any> {
                return __request(OpenAPI, {
                    method: 'DELETE',
                    url: '/category/{categoryID}/delete',
                    path: {
                        'categoryID': categoryId,
                    },
                    errors: {
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Create new construction module
             *
             * @param requestBody
             * @returns any OK
             * @throws ApiError
             */
            public static createConstructionModule(
                requestBody: {
                    constructionID: string;
                    name: string;
                    quantity: number;
                },
            ): CancelablePromise<{
                id: string;
            }> {
                return __request(OpenAPI, {
                    method: 'POST',
                    url: '/cmodule/create',
                    body: requestBody,
                    mediaType: 'application/json',
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        429: `The number of construction modules exceeds the limitation`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * List construction modules
             *
             * @param constructionId
             * @returns ConstructionModuleDetail OK
             * @throws ApiError
             */
            public static listConstructionModules(
                constructionId: string,
            ): CancelablePromise<Array<ConstructionModuleDetail>> {
                return __request(OpenAPI, {
                    method: 'GET',
                    url: '/cmodule/list',
                    query: {
                        'constructionID': constructionId,
                    },
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Search construction modules by prefix
             *
             * @param constructionId
             * @param prefix
             * @returns ConstructionModuleDetail OK
             * @throws ApiError
             */
            public static searchConstructionModulesByPrefix(
                constructionId: string,
                prefix: string,
            ): CancelablePromise<Array<ConstructionModuleDetail>> {
                return __request(OpenAPI, {
                    method: 'GET',
                    url: '/cmodule/search/prefix',
                    query: {
                        'constructionID': constructionId,
                        'prefix': prefix,
                    },
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Get construction module detail
             *
             * @param constructionModuleId
             * @returns ConstructionModuleDetail OK
             * @throws ApiError
             */
            public static getConstructionModule(
                constructionModuleId: string,
            ): CancelablePromise<ConstructionModuleDetail> {
                return __request(OpenAPI, {
                    method: 'GET',
                    url: '/cmodule/{constructionModuleID}/detail',
                    path: {
                        'constructionModuleID': constructionModuleId,
                    },
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        404: `Not Found`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Update a construction module
             *
             * @param constructionModuleId
             * @param requestBody
             * @returns any OK
             * @throws ApiError
             */
            public static updateConstructionModule(
                constructionModuleId: string,
                requestBody: {
                    name: string;
                    quantity: number;
                },
            ): CancelablePromise<any> {
                return __request(OpenAPI, {
                    method: 'POST',
                    url: '/cmodule/{constructionModuleID}/update',
                    path: {
                        'constructionModuleID': constructionModuleId,
                    },
                    body: requestBody,
                    mediaType: 'application/json',
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Create new construction
             *
             * @param requestBody
             * @returns any OK
             * @throws ApiError
             */
            public static createConstruction(
                requestBody: {
                    code: string;
                    name: string;
                    orgID: string;
                },
            ): CancelablePromise<{
                id: string;
            }> {
                return __request(OpenAPI, {
                    method: 'POST',
                    url: '/construction/create',
                    body: requestBody,
                    mediaType: 'application/json',
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        429: `The number of constructions exceeds the limitation`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * List construction
             *
             * @param orgId
             * @param offset
             * @param limit
             * @returns ConstructionDetail OK
             * @throws ApiError
             */
            public static listConstructions(
                orgId: string,
                offset: number,
                limit: number,
            ): CancelablePromise<Array<ConstructionDetail>> {
                return __request(OpenAPI, {
                    method: 'GET',
                    url: '/construction/list',
                    query: {
                        'orgID': orgId,
                        'offset': offset,
                        'limit': limit,
                    },
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Get construction detail
             *
             * @param constructionId
             * @returns ConstructionDetail OK
             * @throws ApiError
             */
            public static getConstruction(
                constructionId: string,
            ): CancelablePromise<ConstructionDetail> {
                return __request(OpenAPI, {
                    method: 'GET',
                    url: '/construction/{constructionID}/detail',
                    path: {
                        'constructionID': constructionId,
                    },
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        404: `Not Found`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Construction - Add member
             *
             * @param constructionId
             * @param requestBody
             * @returns any OK
             * @throws ApiError
             */
            public static addMemberToConstruction(
                constructionId: string,
                requestBody: {
                    accountID: string;
                    role: ConstructionRole;
                },
            ): CancelablePromise<any> {
                return __request(OpenAPI, {
                    method: 'POST',
                    url: '/construction/{constructionID}/member/add',
                    path: {
                        'constructionID': constructionId,
                    },
                    body: requestBody,
                    mediaType: 'application/json',
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        409: `Conflicted / Duplicated`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Grant/Update role for member in Construction
             *
             * @param constructionId
             * @param requestBody
             * @returns any OK
             * @throws ApiError
             */
            public static updateRoleForMemberInConstruction(
                constructionId: string,
                requestBody: {
                    accountID: string;
                    constructionRole: ConstructionRole;
                },
            ): CancelablePromise<any> {
                return __request(OpenAPI, {
                    method: 'PATCH',
                    url: '/construction/{constructionID}/member/grant',
                    path: {
                        'constructionID': constructionId,
                    },
                    body: requestBody,
                    mediaType: 'application/json',
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * List members in the Construction
             *
             * @param constructionId
             * @param offset
             * @param limit
             * @returns ConstructionMemberDetail OK
             * @throws ApiError
             */
            public static listMembersInConstruction(
                constructionId: string,
                offset: number,
                limit: number,
            ): CancelablePromise<Array<ConstructionMemberDetail>> {
                return __request(OpenAPI, {
                    method: 'GET',
                    url: '/construction/{constructionID}/member/list',
                    path: {
                        'constructionID': constructionId,
                    },
                    query: {
                        'offset': offset,
                        'limit': limit,
                    },
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Remove Member from Construction
             *
             * @param constructionId
             * @param requestBody
             * @returns any OK
             * @throws ApiError
             */
            public static removeMemberFromConstruction(
                constructionId: string,
                requestBody: {
                    accountID: string;
                },
            ): CancelablePromise<any> {
                return __request(OpenAPI, {
                    method: 'DELETE',
                    url: '/construction/{constructionID}/member/remove',
                    path: {
                        'constructionID': constructionId,
                    },
                    body: requestBody,
                    mediaType: 'application/json',
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Get construction metadata
             *
             * @param constructionId
             * @returns ConstructionMetadataDetail OK
             * @throws ApiError
             */
            public static getConstructionMetadata(
                constructionId: string,
            ): CancelablePromise<ConstructionMetadataDetail> {
                return __request(OpenAPI, {
                    method: 'GET',
                    url: '/construction/{constructionID}/metadata/detail',
                    path: {
                        'constructionID': constructionId,
                    },
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Put construction metadata
             *
             * @param constructionId
             * @param requestBody
             * @returns any OK
             * @throws ApiError
             */
            public static putConstructionMetadata(
                constructionId: string,
                requestBody: {
                    currency: Currency;
                    otherInfo: Array<StringKeyValue>;
                },
            ): CancelablePromise<any> {
                return __request(OpenAPI, {
                    method: 'PUT',
                    url: '/construction/{constructionID}/metadata/put',
                    path: {
                        'constructionID': constructionId,
                    },
                    body: requestBody,
                    mediaType: 'application/json',
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Update construction
             *
             * @param constructionId
             * @param requestBody
             * @returns any OK
             * @throws ApiError
             */
            public static updateConstruction(
                constructionId: string,
                requestBody: {
                    code: string;
                    name: string;
                },
            ): CancelablePromise<any> {
                return __request(OpenAPI, {
                    method: 'PATCH',
                    url: '/construction/{constructionID}/update',
                    path: {
                        'constructionID': constructionId,
                    },
                    body: requestBody,
                    mediaType: 'application/json',
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Delete construction
             *
             * @param constructionId
             * @returns any OK
             * @throws ApiError
             */
            public static deleteConstruction(
                constructionId: string,
            ): CancelablePromise<any> {
                return __request(OpenAPI, {
                    method: 'DELETE',
                    url: '/construction/{constructionID}/delete',
                    path: {
                        'constructionID': constructionId,
                    },
                    errors: {
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Create equipment attribute tree
             *
             * @param requestBody
             * @returns any OK
             * @throws ApiError
             */
            public static createEquipmentAttributeTree(
                requestBody: {
                    constructionID: string;
                    equipmentClass: EquipmentClass;
                    equipmentClassOther: string;
                    tree: EquipmentAttributeTreeNode;
                    treeType: EquipmentAttributeTreeType;
                },
            ): CancelablePromise<{
                id: string;
            }> {
                return __request(OpenAPI, {
                    method: 'POST',
                    url: '/equipment/attribute-tree/create',
                    body: requestBody,
                    mediaType: 'application/json',
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        409: `Conflicted / Duplicated`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * List equipment attribute trees in a construction
             *
             * @param constructionId
             * @returns EquipmentAttributeTree OK
             * @throws ApiError
             */
            public static listEquipmentAttributeTrees(
                constructionId: string,
            ): CancelablePromise<Array<EquipmentAttributeTree>> {
                return __request(OpenAPI, {
                    method: 'GET',
                    url: '/equipment/attribute-tree/list',
                    query: {
                        'constructionID': constructionId,
                    },
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Delete equipment attribute tree in a construction
             *
             * @param treeId
             * @returns any OK
             * @throws ApiError
             */
            public static deleteEquipmentAttributeTree(
                treeId: string,
            ): CancelablePromise<any> {
                return __request(OpenAPI, {
                    method: 'DELETE',
                    url: '/equipment/attribute-tree/{treeID}/delete',
                    path: {
                        'treeID': treeId,
                    },
                    errors: {
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Get equipment attribute tree
             *
             * @param treeId
             * @returns EquipmentAttributeTree OK
             * @throws ApiError
             */
            public static getEquipmentAttributeTree(
                treeId: string,
            ): CancelablePromise<EquipmentAttributeTree> {
                return __request(OpenAPI, {
                    method: 'GET',
                    url: '/equipment/attribute-tree/{treeID}/detail',
                    path: {
                        'treeID': treeId,
                    },
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        404: `Not Found`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Update equipment attribute tree
             *
             * @param treeId
             * @param requestBody
             * @returns any OK
             * @throws ApiError
             */
            public static updateEquipmentAttributeTree(
                treeId: string,
                requestBody: EquipmentAttributeTreeNode,
            ): CancelablePromise<any> {
                return __request(OpenAPI, {
                    method: 'PATCH',
                    url: '/equipment/attribute-tree/{treeID}/update',
                    path: {
                        'treeID': treeId,
                    },
                    body: requestBody,
                    mediaType: 'application/json',
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * List equipment classes. Class name is translated in corresponding locale.
             *
             * @returns LocaledEquipmentClass OK
             * @throws ApiError
             */
            public static listLocaledEquipmentClasses(): CancelablePromise<Array<LocaledEquipmentClass>> {
                return __request(OpenAPI, {
                    method: 'GET',
                    url: '/equipment/class/list',
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * List global presets
             *
             * @param market
             * @returns GlobalPresetDetail OK
             * @throws ApiError
             */
            public static listGlobalPresets(
                market?: Market,
            ): CancelablePromise<Array<GlobalPresetDetail>> {
                return __request(OpenAPI, {
                    method: 'GET',
                    url: '/global/preset/list',
                    query: {
                        'market': market,
                    },
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Apply/Bind a Global Preset to a Construction. If settings/attributes exist, do nothing.
             *
             * @param globalPresetId
             * @param constructionId
             * @returns any OK
             * @throws ApiError
             */
            public static bindGlobalPreset(
                globalPresetId: string,
                constructionId: string,
            ): CancelablePromise<any> {
                return __request(OpenAPI, {
                    method: 'PATCH',
                    url: '/global/preset/{globalPresetID}/bind/{constructionID}',
                    path: {
                        'globalPresetID': globalPresetId,
                        'constructionID': constructionId,
                    },
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Copy a Global Preset to an Org.
             *
             * @param globalPresetId
             * @param orgId
             * @returns any OK
             * @throws ApiError
             */
            public static copyGlobalPreset(
                globalPresetId: string,
                orgId: string,
            ): CancelablePromise<{
                orgPresetID: string;
            }> {
                return __request(OpenAPI, {
                    method: 'PUT',
                    url: '/global/preset/{globalPresetID}/copy/{orgID}',
                    path: {
                        'globalPresetID': globalPresetId,
                        'orgID': orgId,
                    },
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Get global preset
             *
             * @param globalPresetId
             * @returns GlobalPresetDetail OK
             * @throws ApiError
             */
            public static getGlobalPreset(
                globalPresetId: string,
            ): CancelablePromise<GlobalPresetDetail> {
                return __request(OpenAPI, {
                    method: 'GET',
                    url: '/global/preset/{globalPresetID}/detail',
                    path: {
                        'globalPresetID': globalPresetId,
                    },
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        404: `Not Found`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * List Org Invoices
             *
             * @param orgId
             * @param offset
             * @param limit
             * @returns Invoice OK
             * @throws ApiError
             */
            public static listOrgInvoices(
                orgId: string,
                offset: number,
                limit: number,
            ): CancelablePromise<Array<Invoice>> {
                return __request(OpenAPI, {
                    method: 'GET',
                    url: '/invoice/list',
                    query: {
                        'orgID': orgId,
                        'offset': offset,
                        'limit': limit,
                    },
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Get Invoice Detail
             *
             * @param code
             * @returns InvoiceDetail OK
             * @throws ApiError
             */
            public static getInvoiceDetail(
                code: string,
            ): CancelablePromise<InvoiceDetail> {
                return __request(OpenAPI, {
                    method: 'GET',
                    url: '/invoice/{code}/detail',
                    path: {
                        'code': code,
                    },
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        404: `Not Found`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Logout
             *
             * @returns string OK
             * @throws ApiError
             */
            public static logout(): CancelablePromise<string> {
                return __request(OpenAPI, {
                    method: 'POST',
                    url: '/logout',
                    responseHeader: 'Set-Cookie',
                });
            }
            /**
             * Delete Org Invitation
             *
             * @param invitationId
             * @returns any OK
             * @throws ApiError
             */
            public static deleteOrgInvitation(
                invitationId: string,
            ): CancelablePromise<any> {
                return __request(OpenAPI, {
                    method: 'DELETE',
                    url: '/oinvite/{invitationID}/delete',
                    path: {
                        'invitationID': invitationId,
                    },
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * List Orgs
             *
             * @param offset
             * @param limit
             * @returns OrgDetail OK
             * @throws ApiError
             */
            public static listOrgs(
                offset: number,
                limit: number,
            ): CancelablePromise<Array<OrgDetail>> {
                return __request(OpenAPI, {
                    method: 'GET',
                    url: '/org/list',
                    query: {
                        'offset': offset,
                        'limit': limit,
                    },
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Delete an equipment attribute tree in org preset
             *
             * @param treeId
             * @returns any OK
             * @throws ApiError
             */
            public static deleteEquipmentAttributeTreeInOrgPreset(
                treeId: string,
            ): CancelablePromise<any> {
                return __request(OpenAPI, {
                    method: 'DELETE',
                    url: '/org/preset/equipment/attribute/{treeID}/delete',
                    path: {
                        'treeID': treeId,
                    },
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Get equipment attribute tree in org preset
             *
             * @param treeId
             * @returns EquipmentAttributeTreeInOrgPreset OK
             * @throws ApiError
             */
            public static getEquipmentAttributeTreeInOrgPreset(
                treeId: string,
            ): CancelablePromise<EquipmentAttributeTreeInOrgPreset> {
                return __request(OpenAPI, {
                    method: 'GET',
                    url: '/org/preset/equipment/attribute/{treeID}/detail',
                    path: {
                        'treeID': treeId,
                    },
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        404: `Not Found`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Update an equipment attribute tree in org preset
             *
             * @param treeId
             * @param requestBody
             * @returns any OK
             * @throws ApiError
             */
            public static updateEquipmentAttributeTreeInOrgPreset(
                treeId: string,
                requestBody: EquipmentAttributeTreeNode,
            ): CancelablePromise<any> {
                return __request(OpenAPI, {
                    method: 'PATCH',
                    url: '/org/preset/equipment/attribute/{treeID}/update',
                    path: {
                        'treeID': treeId,
                    },
                    body: requestBody,
                    mediaType: 'application/json',
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Apply/Bind a Org Preset to a Construction. If settings/attributes exist, do nothing.
             *
             * @param orgPresetId
             * @param constructionId
             * @returns any OK
             * @throws ApiError
             */
            public static bindOrgPreset(
                orgPresetId: string,
                constructionId: string,
            ): CancelablePromise<any> {
                return __request(OpenAPI, {
                    method: 'PATCH',
                    url: '/org/preset/{orgPresetID}/bind/{constructionID}',
                    path: {
                        'orgPresetID': orgPresetId,
                        'constructionID': constructionId,
                    },
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Delete org preset
             *
             * @param orgPresetId
             * @returns any OK
             * @throws ApiError
             */
            public static deleteOrgPreset(
                orgPresetId: string,
            ): CancelablePromise<any> {
                return __request(OpenAPI, {
                    method: 'DELETE',
                    url: '/org/preset/{orgPresetID}/delete',
                    path: {
                        'orgPresetID': orgPresetId,
                    },
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Get org preset
             *
             * @param orgPresetId
             * @returns OrgPresetDetail OK
             * @throws ApiError
             */
            public static getOrgPreset(
                orgPresetId: string,
            ): CancelablePromise<OrgPresetDetail> {
                return __request(OpenAPI, {
                    method: 'GET',
                    url: '/org/preset/{orgPresetID}/detail',
                    path: {
                        'orgPresetID': orgPresetId,
                    },
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        404: `Not Found`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Create an equipment attribute tree in org preset
             *
             * @param orgPresetId
             * @param requestBody
             * @returns any OK
             * @throws ApiError
             */
            public static createEquipmentAttributeTreeInOrgPreset(
                orgPresetId: string,
                requestBody: {
                    equipmentClass: EquipmentClass;
                    equipmentClassOther: string;
                    tree: EquipmentAttributeTreeNode;
                    treeType: EquipmentAttributeTreeType;
                },
            ): CancelablePromise<{
                id: string;
            }> {
                return __request(OpenAPI, {
                    method: 'POST',
                    url: '/org/preset/{orgPresetID}/equipment/attribute/create',
                    path: {
                        'orgPresetID': orgPresetId,
                    },
                    body: requestBody,
                    mediaType: 'application/json',
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        404: `Org Preset Not Found`,
                        409: `Conflicted / Duplicated`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * List equipment attribute trees in org preset
             *
             * @param orgPresetId
             * @returns EquipmentAttributeTreeInOrgPreset OK
             * @throws ApiError
             */
            public static listEquipmentAttributeTreesInOrgPreset(
                orgPresetId: string,
            ): CancelablePromise<Array<EquipmentAttributeTreeInOrgPreset>> {
                return __request(OpenAPI, {
                    method: 'GET',
                    url: '/org/preset/{orgPresetID}/equipment/attribute/list',
                    path: {
                        'orgPresetID': orgPresetId,
                    },
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Update org preset
             *
             * @param orgPresetId
             * @param requestBody
             * @returns any OK
             * @throws ApiError
             */
            public static updateOrgPreset(
                orgPresetId: string,
                requestBody: {
                    description: string;
                    locale: Locale;
                    market: Market;
                    name: string;
                },
            ): CancelablePromise<any> {
                return __request(OpenAPI, {
                    method: 'PUT',
                    url: '/org/preset/{orgPresetID}/update',
                    path: {
                        'orgPresetID': orgPresetId,
                    },
                    body: requestBody,
                    mediaType: 'application/json',
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Get Org detail
             *
             * @param orgId
             * @returns OrgDetail OK
             * @throws ApiError
             */
            public static getOrgDetail(
                orgId: string,
            ): CancelablePromise<OrgDetail> {
                return __request(OpenAPI, {
                    method: 'GET',
                    url: '/org/{orgID}/detail',
                    path: {
                        'orgID': orgId,
                    },
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        404: `Not Found`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Get Org Stats
             *
             * @param orgId
             * @returns OrgStats OK
             * @throws ApiError
             */
            public static getOrgStats(
                orgId: string,
            ): CancelablePromise<OrgStats> {
                return __request(OpenAPI, {
                    method: 'GET',
                    url: '/org/{orgID}/stats',
                    path: {
                        'orgID': orgId,
                    },
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        404: `Not Found`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * List invitations
             *
             * @param orgId
             * @param status
             * @param offset
             * @param limit
             * @returns OrgInvitationDetail OK
             * @throws ApiError
             */
            public static listOrgInvitations(
                orgId: string,
                status: OrgInvitationStatus,
                offset: number,
                limit: number,
            ): CancelablePromise<Array<OrgInvitationDetail>> {
                return __request(OpenAPI, {
                    method: 'GET',
                    url: '/org/{orgID}/invitations',
                    path: {
                        'orgID': orgId,
                    },
                    query: {
                        'status': status,
                        'offset': offset,
                        'limit': limit,
                    },
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Org - Invitation
             *
             * @param orgId
             * @param requestBody
             * @returns any OK
             * @throws ApiError
             */
            public static orgInvite(
                orgId: string,
                requestBody: {
                    email: string;
                    locale: Locale;
                    role: OrgRole;
                },
            ): CancelablePromise<{
                invitationID: string;
            }> {
                return __request(OpenAPI, {
                    method: 'POST',
                    url: '/org/{orgID}/invite',
                    path: {
                        'orgID': orgId,
                    },
                    body: requestBody,
                    mediaType: 'application/json',
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        409: `Conflicted / Duplicated`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Grant/Update role for member in Org
             *
             * @param orgId
             * @param requestBody
             * @returns any OK
             * @throws ApiError
             */
            public static updateRoleForMemberInOrg(
                orgId: string,
                requestBody: {
                    accountID: string;
                    orgRole: OrgRole;
                },
            ): CancelablePromise<any> {
                return __request(OpenAPI, {
                    method: 'PATCH',
                    url: '/org/{orgID}/member/grant',
                    path: {
                        'orgID': orgId,
                    },
                    body: requestBody,
                    mediaType: 'application/json',
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * List members in the Org
             *
             * @param orgId
             * @param offset
             * @param limit
             * @returns OrgMemberDetail OK
             * @throws ApiError
             */
            public static listMembersInOrg(
                orgId: string,
                offset: number,
                limit: number,
            ): CancelablePromise<Array<OrgMemberDetail>> {
                return __request(OpenAPI, {
                    method: 'GET',
                    url: '/org/{orgID}/member/list',
                    path: {
                        'orgID': orgId,
                    },
                    query: {
                        'offset': offset,
                        'limit': limit,
                    },
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Remove Member from Org
             *
             * @param orgId
             * @param requestBody
             * @returns any OK
             * @throws ApiError
             */
            public static removeMemberFromOrg(
                orgId: string,
                requestBody: {
                    accountID: string;
                },
            ): CancelablePromise<any> {
                return __request(OpenAPI, {
                    method: 'DELETE',
                    url: '/org/{orgID}/member/remove',
                    path: {
                        'orgID': orgId,
                    },
                    body: requestBody,
                    mediaType: 'application/json',
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Create org preset
             *
             * @param orgId
             * @param requestBody
             * @returns any OK
             * @throws ApiError
             */
            public static createOrgPreset(
                orgId: string,
                requestBody: {
                    description: string;
                    locale: Locale;
                    market: Market;
                    name: string;
                },
            ): CancelablePromise<{
                orgPresetID: string;
            }> {
                return __request(OpenAPI, {
                    method: 'POST',
                    url: '/org/{orgID}/preset/create',
                    path: {
                        'orgID': orgId,
                    },
                    body: requestBody,
                    mediaType: 'application/json',
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * List org presets
             *
             * @param orgId
             * @param market
             * @returns OrgPresetDetail OK
             * @throws ApiError
             */
            public static listOrgPresets(
                orgId: string,
                market?: Market,
            ): CancelablePromise<Array<OrgPresetDetail>> {
                return __request(OpenAPI, {
                    method: 'GET',
                    url: '/org/{orgID}/preset/list',
                    path: {
                        'orgID': orgId,
                    },
                    query: {
                        'market': market,
                    },
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Update Org info
             *
             * @param orgId
             * @param requestBody
             * @returns any OK
             * @throws ApiError
             */
            public static updateOrg(
                orgId: string,
                requestBody: {
                    address: string;
                    email: string;
                    name: string;
                    phone: string;
                    taxCode: string;
                },
            ): CancelablePromise<any> {
                return __request(OpenAPI, {
                    method: 'PATCH',
                    url: '/org/{orgID}/update',
                    path: {
                        'orgID': orgId,
                    },
                    body: requestBody,
                    mediaType: 'application/json',
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * List Org Reporting Templates
             *
             * @param orgId
             * @returns OrgReportingTemplateDetail OK
             * @throws ApiError
             */
            public static listOrgReportingTemplates(
                orgId: string,
            ): CancelablePromise<Array<OrgReportingTemplateDetail>> {
                return __request(OpenAPI, {
                    method: 'GET',
                    url: '/org/{orgID}/reporting/template/list',
                    path: {
                        'orgID': orgId,
                    },
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Delete pages
             *
             * @param requestBody
             * @returns any OK
             * @throws ApiError
             */
            public static deletePages(
                requestBody: {
                    categoryID: string;
                    pageIDs: Array<string>;
                },
            ): CancelablePromise<any> {
                return __request(OpenAPI, {
                    method: 'DELETE',
                    url: '/page/delete/many',
                    body: requestBody,
                    mediaType: 'application/json',
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        423: `category/page is locked`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Insert new page(s)
             *
             * @param formData
             * @returns any OK
             * @throws ApiError
             */
            public static insertPages(
                formData: {
                    afterPage?: string;
                    beforePage?: string;
                    categoryID: string;
                    file: Blob;
                    /**
                     * Due to issue https://github.com/ferdikoomen/openapi-typescript-codegen/issues/1049,
                     * we must define this field as string instead of object. In this way, Frontend side
                     * must JSON.stringify before sending the request.
                     *
                     */
                    pageNumbers: string;
                },
            ): CancelablePromise<any> {
                return __request(OpenAPI, {
                    method: 'POST',
                    url: '/page/insert',
                    formData: formData,
                    mediaType: 'multipart/form-data',
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        422: `Insufficient Storage / Page Pool`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * List pages
             *
             * @param categoryId
             * @param offset
             * @param limit
             * @returns PageDetail OK
             * @throws ApiError
             */
            public static listPages(
                categoryId: string,
                offset: number,
                limit: number,
            ): CancelablePromise<Array<PageDetail>> {
                return __request(OpenAPI, {
                    method: 'GET',
                    url: '/page/list',
                    query: {
                        'categoryID': categoryId,
                        'offset': offset,
                        'limit': limit,
                    },
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        404: `Not Found`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Clone page
             *
             * @param pageId
             * @returns any OK
             * @throws ApiError
             */
            public static clonePage(
                pageId: string,
            ): CancelablePromise<{
                newPageID: string;
            }> {
                return __request(OpenAPI, {
                    method: 'POST',
                    url: '/page/{pageID}/clone',
                    path: {
                        'pageID': pageId,
                    },
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        422: `Insufficient Storage / Page Pool`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Delete page
             *
             * @param pageId
             * @returns any OK
             * @throws ApiError
             */
            public static deletePage(
                pageId: string,
            ): CancelablePromise<any> {
                return __request(OpenAPI, {
                    method: 'DELETE',
                    url: '/page/{pageID}/delete',
                    path: {
                        'pageID': pageId,
                    },
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        423: `category/page is locked`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Get page detail
             *
             * @param pageId
             * @param omitPdf
             * @returns PageDetail OK
             * @throws ApiError
             */
            public static getPageDetail(
                pageId: string,
                omitPdf?: boolean,
            ): CancelablePromise<PageDetail> {
                return __request(OpenAPI, {
                    method: 'GET',
                    url: '/page/{pageID}/detail',
                    path: {
                        'pageID': pageId,
                    },
                    query: {
                        'omitPDF': omitPdf,
                    },
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        404: `Not Found`,
                        429: `The number of downloads exceeds the limitation`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Replace page
             *
             * @param pageId
             * @param formData
             * @returns any OK
             * @throws ApiError
             */
            public static replacePage(
                pageId: string,
                formData: {
                    file: Blob;
                    pageNumber: string;
                },
            ): CancelablePromise<any> {
                return __request(OpenAPI, {
                    method: 'PATCH',
                    url: '/page/{pageID}/replace',
                    path: {
                        'pageID': pageId,
                    },
                    formData: formData,
                    mediaType: 'multipart/form-data',
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        422: `Insufficient Storage`,
                        423: `Category/Page is locked`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Rotate page
             *
             * @param pageId
             * @param requestBody
             * @returns any OK
             * @throws ApiError
             */
            public static rotatePage(
                pageId: string,
                requestBody: {
                    angle: RotationAngle;
                },
            ): CancelablePromise<any> {
                return __request(OpenAPI, {
                    method: 'PATCH',
                    url: '/page/{pageID}/rotate',
                    path: {
                        'pageID': pageId,
                    },
                    body: requestBody,
                    mediaType: 'application/json',
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        423: `category/page is locked`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Update page
             *
             * @param pageId
             * @param requestBody
             * @returns any OK
             * @throws ApiError
             */
            public static updatePage(
                pageId: string,
                requestBody: {
                    name: string;
                    scale: number;
                },
            ): CancelablePromise<any> {
                return __request(OpenAPI, {
                    method: 'PATCH',
                    url: '/page/{pageID}/update',
                    path: {
                        'pageID': pageId,
                    },
                    body: requestBody,
                    mediaType: 'application/json',
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        423: `category/page is locked`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Exchange Page (from Page Pool) for AI Prediction
             *
             * @param pageId
             * @returns any OK
             * @throws ApiError
             */
            public static exchangePageForAiPrediction(
                pageId: string,
            ): CancelablePromise<any> {
                return __request(OpenAPI, {
                    method: 'PATCH',
                    url: '/page/{pageID}/exchange_page_for_ai_prediction',
                    path: {
                        'pageID': pageId,
                    },
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        404: `Not Found`,
                        422: `No more page (in Page Pool) to exchange`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Login to Hais
             *
             * @param requestBody
             * @returns string OK
             * @returns any Partially OK
             * @throws ApiError
             */
            public static login(
                requestBody: {
                    email: string;
                    password: string;
                },
            ): CancelablePromise<string | {
                totp: boolean;
            }> {
                return __request(OpenAPI, {
                    method: 'POST',
                    url: '/public/login',
                    body: requestBody,
                    mediaType: 'application/json',
                    responseHeader: 'Set-Cookie',
                    errors: {
                        400: `Bad Request`,
                        401: `unauthorized`,
                        404: `account not found`,
                        423: `account is locked`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Login to Hais
             *
             * @param requestBody
             * @returns string OK
             * @throws ApiError
             */
            public static loginTotp(
                requestBody: {
                    passcode: string;
                },
            ): CancelablePromise<string> {
                return __request(OpenAPI, {
                    method: 'POST',
                    url: '/public/login_totp',
                    body: requestBody,
                    mediaType: 'application/json',
                    responseHeader: 'Set-Cookie',
                    errors: {
                        400: `Bad Request`,
                        401: `unauthorized`,
                        404: `account not found`,
                        423: `account is locked`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Accept Org Invitation
             *
             * @param requestBody
             * @returns any OK
             * @throws ApiError
             */
            public static acceptOrgInvite(
                requestBody: {
                    companyName: string;
                    firstName: string;
                    firstNameKana: string;
                    lastName: string;
                    lastNameKana: string;
                    locale: Locale;
                    middleName: string;
                    middleNameKana: string;
                    password: string;
                    phone: string;
                    token: string;
                },
            ): CancelablePromise<{
                orgID: string;
            }> {
                return __request(OpenAPI, {
                    method: 'POST',
                    url: '/public/oinvite/accept',
                    body: requestBody,
                    mediaType: 'application/json',
                    errors: {
                        400: `Bad Request`,
                        404: `Not Found`,
                        409: `Conflicted / Duplicated`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Accept Org Invitation - Preflight
             *
             * @param requestBody
             * @returns any OK
             * @throws ApiError
             */
            public static acceptOrgInvitePreflight(
                requestBody: {
                    token: string;
                },
            ): CancelablePromise<{
                email: string;
                orgID: string;
                orgName: string;
                shouldCreateAccount: boolean;
            }> {
                return __request(OpenAPI, {
                    method: 'POST',
                    url: '/public/oinvite/accept/preflight',
                    body: requestBody,
                    mediaType: 'application/json',
                    errors: {
                        400: `Bad Request`,
                        404: `Not Found`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Reset password
             *
             * @param requestBody
             * @returns any OK
             * @throws ApiError
             */
            public static resetPassword(
                requestBody: {
                    newPassword: string;
                    token: string;
                },
            ): CancelablePromise<any> {
                return __request(OpenAPI, {
                    method: 'PATCH',
                    url: '/public/reset-password',
                    body: requestBody,
                    mediaType: 'application/json',
                    errors: {
                        400: `Bad Request`,
                        404: `account not found`,
                        423: `account is locked`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Reset password by email
             *
             * @param requestBody
             * @returns any OK
             * @throws ApiError
             */
            public static resetPasswordByEmail(
                requestBody: {
                    email: string;
                },
            ): CancelablePromise<any> {
                return __request(OpenAPI, {
                    method: 'POST',
                    url: '/public/reset-password-email',
                    body: requestBody,
                    mediaType: 'application/json',
                    errors: {
                        400: `Bad Request`,
                        404: `account not found`,
                        423: `account is locked`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Uptime Check
             *
             * @returns any OK
             * @throws ApiError
             */
            public static uptimeCheck(): CancelablePromise<any> {
                return __request(OpenAPI, {
                    method: 'GET',
                    url: '/public/uptime_check',
                    errors: {
                        503: `Not Available`,
                    },
                });
            }
            /**
             * Sync Windows/Doors.
             *
             * @param requestBody
             * @returns any OK
             * @throws ApiError
             */
            public static syncWindoors(
                requestBody: {
                    pageID: string;
                    windoors: Array<WindoorCreate>;
                },
            ): CancelablePromise<any> {
                return __request(OpenAPI, {
                    method: 'PUT',
                    url: '/ykkap/windoor/sync',
                    body: requestBody,
                    mediaType: 'application/json',
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        423: `category/page is locked`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * List Windows/Doors
             * @param pageId
             * @returns WindoorDetail OK
             * @throws ApiError
             */
            public static listWindoors(
                pageId: string,
            ): CancelablePromise<Array<WindoorDetail>> {
                return __request(OpenAPI, {
                    method: 'GET',
                    url: '/ykkap/windoor/list',
                    query: {
                        'pageID': pageId,
                    },
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        500: `Internal Server Error`,
                    },
                });
            }
            /**
             * Predict Windows/Doors.
             *
             * @param formData
             * @returns AIModelOutputWindoor
             * @throws ApiError
             */
            public static predictWindoors(
                formData: {
                    pageID: string;
                    up_crop_id: string;
                    up_crop_image: Blob;
                    down_crop_id: string;
                    down_crop_image: Blob;
                    left_crop_id: string;
                    left_crop_image: Blob;
                    right_crop_id: string;
                    right_crop_image: Blob;
                    /**
                     * Due to issue https://github.com/ferdikoomen/openapi-typescript-codegen/issues/1049,
                     * we must define this field as string instead of object. In this way, Frontend side
                     * must JSON.stringify before sending the request.
                     *
                     */
                    floor_numbers: string;
                    floor_crop_ids: Array<string>;
                    floor_crop_images: Array<Blob>;
                    session_id: string;
                },
            ): CancelablePromise<AIModelOutputWindoor> {
                return __request(OpenAPI, {
                    method: 'POST',
                    url: '/ykkap/ai/predict/windoor',
                    formData: formData,
                    mediaType: 'multipart/form-data',
                    errors: {
                        400: `Bad Request`,
                        401: `Unauthorized - Invalid Authentication Credentials`,
                        403: `Forbidden`,
                        422: `Unprocessable Content`,
                        429: `The number of AI Prediction requests exceeds the limitation`,
                        500: `Internal Server Error`,
                    },
                });
            }
        }
