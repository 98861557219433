import {
  Body1,
  Dropdown,
  Field,
  Option,
  OptionGroup,
  Panel,
  makeStyles,
  shorthands,
  tokens,
} from '@aisekisan/bond'
import type { UseFormReturn } from 'react-hook-form'
import { type ReactElement, useState } from 'react'
import { useGlobalPresetList, useOrgPresetList } from '@aisekisan/anya-api'
import { PresetThumbnailCard } from '../preset-thumb-card'
import type { ConstructionFormValues } from './type'
import { T } from '@/libs/intl/t'
import { useT } from '@/libs/intl/useT'

const useStyles = makeStyles({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    columnGap: tokens.spacingHorizontalL,
    rowGap: tokens.spacingHorizontalL,
  },
  help: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalS,
  },
  placeholder: {
    width: '320px',
    ...shorthands.borderRadius(tokens.borderRadiusLarge),
    ...shorthands.border('2px', 'dashed', tokens.colorNeutralStroke1),
  },
})

interface Props {
  orgID: string
  form: UseFormReturn<ConstructionFormValues>
}

export function ConstructionPreset(props: Props): ReactElement {
  const { orgID, form } = props
  const { formState: { errors } } = form

  const styles = useStyles()
  const t = useT()

  const [value, setValue] = useState('')
  const [selectedOptions, setSelectedOptions] = useState<string[]>([''])

  const orgPresetList = useOrgPresetList({ orgID }).data ?? []
  const globalPresetList = useGlobalPresetList({}).data ?? []

  const onOptionSelect = (_event: any, data: any): void => {
    const values = data.selectedOptions[0].split('/')
    setSelectedOptions(data.selectedOptions)
    setValue(data.optionText ?? '')
    form.setValue('presetID', values[0])
    form.setValue('presetType', values[1])
  }

  return (
    <Panel>
      <div className={styles.container}>
        <Field
          label={<T id="construction.field.preset" />}
          validationState={errors.code ? 'error' : 'none'}
          validationMessage={errors.code?.message}
          hint={(
            <div className={styles.help}>
              <Body1>
                <T id="construction.preset.helper.1" />
              </Body1>
              <Body1>
                <T id="construction.preset.helper.2" />
              </Body1>
              <Body1>
                <T id="construction.preset.helper.3" />
              </Body1>
            </div>
          )}
        >
          <Dropdown
            size="large"
            value={value}
            selectedOptions={selectedOptions}
            onOptionSelect={onOptionSelect}
          >
            <OptionGroup label={t('preset.type.global_preset')}>
              {globalPresetList.map(option => (
                <Option
                  key={option.id}
                  value={`${option.id}/globalPreset`}
                >
                  {option.name}
                </Option>
              ))}
            </OptionGroup>
            <OptionGroup label={t('preset.type.org_preset')}>
              {orgPresetList.map(option => (
                <Option key={option.id} value={`${option.id}/orgPreset`}>
                  {option.name}
                </Option>
              ))}
            </OptionGroup>
          </Dropdown>
        </Field>
        {selectedOptions[0] !== ''
          ? <PresetThumbnailCard option={selectedOptions[0]} />
          : <div className={styles.placeholder} />}
      </div>
    </Panel>
  )
}
