import { Title3, makeStyles, shorthands, tokens } from '@aisekisan/bond'
import type { ReactNode } from 'react'
import { AppHeader } from './components/layout/header'
import type { BreadcrumbItem } from './components/layout/type'

const useClasses = makeStyles({
  root: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    flexDirection: 'column',
    overflowX: 'hidden',
    backgroundColor: tokens.colorNeutralBackground4,
    flexWrap: 'wrap',
  },
  fullHeight: {
    height: '100vh',
    justifyContent: 'flex-start',
    flexWrap: 'unset',
  },
  main: {
    width: '100%',
    boxSizing: 'border-box',
    ...shorthands.padding(tokens.spacingHorizontalS),
  },
  title: {
    marginBottom: tokens.spacingVerticalL,
  },
  children: {
    display: 'flex',
    backgroundColor: tokens.colorNeutralBackground4,
    rowGap: tokens.spacingVerticalL,
    flexGrow: 1,
    position: 'relative',
    flexWrap: 'wrap',
    columnGap: tokens.spacingHorizontalL,
  },
})

export function AppPage(props: {
  children: ReactNode
  title?: ReactNode
  breadcrumbs?: BreadcrumbItem[]
  fullHeight?: boolean
}) {
  const classes = useClasses()

  const { children, title, breadcrumbs, fullHeight } = props
  return (
    <div className={[classes.root, fullHeight ? classes.fullHeight : ''].join(' ')}>
      <AppHeader breadcrumbs={breadcrumbs} />
      <div className={classes.main}>
        {title && (
          <div className={classes.title}>
            <Title3>{title}</Title3>
          </div>
        )}
        <div className={classes.children}>{children}</div>
      </div>
    </div>
  )
}
